* {
  margin: 0px;
  padding: 0px;
}

/* ----- hide ie tools ----- */
input::-ms-clear {
  visibility: hidden;
}

/* ----- react-tabs ----- */
.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
  background: rgb(203, 224, 239);
}

.react-tabs__tab--selected {
  background: rgb(25, 95, 190);
  border-color: #aaa;
  color: white;
  border-radius: 5px 5px 0 0;
  pointer-events: none;
}
